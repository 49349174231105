//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import { mapState, mapActions } from "vuex";
//import Cart from "@/components/cart";
import { Plotly } from 'vue-plotly';

export default {

  components: {
    Plotly
  },

  data: function () {
    return {

        peopleSelected:[],
        people: [
          { header: 'Group 1' },
          { name: 'Sandra Adams', group: 'Group 1' },
          { name: 'Ali Connors', group: 'Group 1' },
          { name: 'Trevor Hansen', group: 'Group 1' },
          { name: 'Tucker Smith', group: 'Group 1'},
          { divider: true },
          { header: 'Group 2' },
          { name: 'Britta Holt', group: 'Group 2'},
          { name: 'Jane Smith ', group: 'Group 2'},
          { name: 'John Smith', group: 'Group 2' },
          { name: 'Sandra Williams', group: 'Group 2' }
        ],

        units: null,


        graph_sensors:null,
        get_error: null,

        var_1: {name:'Velocidad', value:{name:'Velocidad',type:'ecu'}},
        var_2: {name:'Altitud',value:{name:'Altitud',type:'ecu'}},
        time_base_1: 'h',
        time_base_2: 'h',
        time_base: [ {name:'Hora', value:'h'},
                     {name:'Dia', value:'d'},
                     {name:'Semana', value:'w'},
                     {name:'Mes', value:'m'}
        ],

        select_vars:[],

        data1:[{  x: [], y: [], type:"bar", mode: 'lines+markers' }],

        data2:[{  x: [], y: [], type:"bar", mode: 'lines+markers'  }],

        layout1:{
          margin:5,
          yaxis: { title:''}
        },
        layout2:{
          margin:5,
          yaxis: { title:''}
        },


      
    }
  },
  //components: {
  //  Cart
  //},
  created() {
    console.log('create -> [Camiones / graficos]');
    
    if (this.$util.isNull(this.$store.state.truck)) {
       this.$router.push("/main/camiones_paneles");
       return;
    }

    console.log(this.$store.state.truck);

    this.$store.state.ts.loader.show = true;

    // Lee variables posibles del camion
    this.$axios.all( [
      this.$axios.get('/api/samplelast/', 
               { params: { truckid: this.$store.state.truck.truckid} } ),                     
    ])
    .then(this.$axios.spread((samplelast_resp)  => {

      this.$util.log(samplelast_resp);

      this.graph_sensors = samplelast_resp.data.vars.ecu;
      console.log('samplelast_resp.units.................................................');
      console.log(samplelast_resp.data.units);
      this.units = samplelast_resp.data.units;
      this.select_vars.push({header:'ECU'});
      let x1 = samplelast_resp.data.vars.ecu.map(function(v) {return {name:v, value:{name:v,type:'ecu'}, group:'ECU'} });
      this.select_vars = this.select_vars.concat(x1);
      this.select_vars.push({ divider: true });
      this.select_vars.push({header:'RECORD'});
      let x2 =  samplelast_resp.data.vars.record.map(function(v) {return {name:v, value:{name:v,type:'record'},group:'RECORD'} });
      this.select_vars = this.select_vars.concat(x2);


      this.$util.log('xxxxxxxxxxx');
      this.$util.log(this.select_vars);

      this.getSamplesHistory(null);

      //this.$store.state.ts.loader.show = false;
      //this.get_error=false;

      /*
      for (let i=0; i<300; i++) {
        this.data1[0].x.push(i);
        this.data1[0].y.push(Math.sin(i*0.1));

        this.data2[0].x.push(i);
        this.data2[0].y.push(Math.sin(i*0.1));
      }
      */

      //this.$store.state.trucks = truck_resp.data;
      //this.status_trucks = status_resp.data;
      //this.$store.state.ts.loader.show = false;
      //this.show=true;
    }))
    .catch((samplelast_err) => {
      this.get_error=true;
      this.$util.log(samplelast_err);
      this.$store.state.ts.loader.show = false;
    });

    
    //this.$store.state.ts.loader.show = false;

  },
  computed: {

  },
  methods: {


    setYAxisTitle(var_n,name) {
          console.log('setYAxisTitle.......................................');
          console.log(this.units);
          if (this.$util.isNull(this.units)) return;
 
          if (var_n==1) {
              if (name in this.units) {
                  this.layout1 = {
                      margin:5,
                     yaxis: { title: this.units[name] }
                  };
              }
              else {
                  this.layout1 = { margin:5, yaxis: { title: '' } } ;
              }          
          }
          if (var_n==2) {
              if (name in this.units) {
                  this.layout2 = {
                      margin:5,
                     yaxis: { title: this.units[name] }
                  };
              }
              else {
                  this.layout1 = { margin:5, yaxis: { title: '' } } ;
              }
          }
    },
    

    getSamplesHistory: function(var_n) {
  
      this.$store.state.ts.loader.show = true;

      // Lee variables posibles del camion
      this.$axios.all( [
          
        ((var_n==null)||(var_n==1))?
        this.$axios.get('/api/samplehistory/', 
                { params: { truckid: this.$store.state.truck.truckid,
                            time_base: this.time_base_1, var: this.var_1 } } ):null,
                            
        ((var_n==null)||(var_n==2))?
        this.$axios.get('/api/samplehistory/', 
                { params: { truckid: this.$store.state.truck.truckid,
                            time_base: this.time_base_2, var: this.var_2 } } ):null,
                            
      ])
      .then(this.$axios.spread((samplehistory_1_resp,samplehistory_2_resp)  => {

        this.$util.log('this.var_1 -->');
        this.$util.log(this.var_1.value.name);
        console.log(samplehistory_1_resp);
        console.log(samplehistory_2_resp);

        if ((var_n==null)||(var_n==1)) {
          this.data1[0].x = samplehistory_1_resp.data.x;
          this.data1[0].y = samplehistory_1_resp.data.y;
          this.setYAxisTitle(1,this.var_1.value.name);
        }
        if ((var_n==null)||(var_n==2)) {
          this.data2[0].x = samplehistory_2_resp.data.x;
          this.data2[0].y = samplehistory_2_resp.data.y;
          this.setYAxisTitle(1,this.var_1.value.name);
        }
        this.get_error=false;
        this.$store.state.ts.loader.show = false;

      }))
      .catch((samplehistory_1_err,samplehistory_2_err) => {
        this.get_error=true;
        this.$store.state.ts.loader.show = false;
        this.$util.log(samplehistory_1_err);
        this.$util.log(samplehistory_2_err);
      });


    },
    
    onChangeVar: function(var_n) {
    
      console.log(var_n);

      this.$util.log(this.var_1);

      if (var_n==1) {
        this.var_1 = {name:'Velocidad', value:this.var_1};
      }
      else {
        this.var_2 = {name:'Altitud', value:this.var_2};
      }

      this.getSamplesHistory(var_n);
      /*
      if (var_n==1) {
        console.log("Var_1");
        this.data1[0].y=[];
        for (let i=0; i<100; i++) {
          this.data1[0].y.push(Math.sin(i*(Math.floor(Math.random() * 10)+1)));
        }
      }
      else {
        this.data2[0].y=[];
        for (let i=0; i<100; i++) {
          this.data2[0].y.push(Math.sin(i*(Math.floor(Math.random() * 10)+1)));
        }
      }
      */
    },
    
    onChangeTimeBase(var_n) {
        this.getSamplesHistory(var_n);
    }
    
  }
};
